import React from 'react';
import Button from '@mui/material/Button';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';

require('./style.css');

const HomeTour = () => {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    useModalOverlay: true,
  });

  const title = 'Welcome to Backstage!';
  const commonConfig = {
    highlightClass: 'highlight',
    scrollTo: true,
    cancelIcon: {
      enabled: true,
    },
  };

  const defaultButtons = [
    {
      classes: 'shepherd-button-primary',
      text: 'Cancel',
      action: tour.cancel,
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Next',
      action: tour.next,
    },
  ];

  const middleButtons = [
    {
      classes: 'shepherd-button-primary',
      text: 'Back',
      action: tour.back,
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Next',
      action: tour.next,
    },
  ];

  const lastButtons = [
    {
      classes: 'shepherd-button-primary',
      text: 'Back',
      action: tour.back,
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Finish',
      action: tour.complete,
    },
  ];

  tour.addSteps([
    {
      attachTo: { element: '.search-container', on: 'bottom' },
      buttons: defaultButtons,
      ...commonConfig,
      title,
      text: "Here you can search the whole catalog, for example you can search for a person's name, a tribe, a chapter, a product, etc.",
    },
    {
      attachTo: { element: '.home-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'This is the actual Home page.',
    },
    {
      attachTo: { element: '.explore-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'Here you can browse in a visual way part of our ecosystem.',
    },
    {
      attachTo: { element: '.catalog-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: "This is the full catalog, is a collection of all the company's services, products, persons, chapters, etc...",
    },
    {
      attachTo: { element: '.api-docs-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'Here you can find all the APIs docs presents in the catalog and inspect them.',
    },
    {
      attachTo: { element: '.docs-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: "Here you can find all the codebase's documentations present in the catalog.",
    },
    {
      attachTo: { element: '.infra-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'In this section, explore comprehensive details about our infrastructure, including dynamic AWS architecture diagrams and real-time insights into Helm releases within the central cluster.',
    },
    {
      attachTo: { element: '.github-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'Here you can see all the repositories in GitHub with some relevant information about them.',
    },
    {
      attachTo: { element: '.costinsights-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'Here you can see the cost insights of the company which will show you engagement vs cost metrics across all the projects we currently have in Snowflake.',
    },
    {
      attachTo: { element: '.tech-radars-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'Here you can see all the Tech Radars registered in the catalog, this data is fetched by Notion, you can find more info <a href="https://www.notion.so/winasone/Tech-Radar-17a2318e159d40d6b7405d36e4aa3804" style="color:#2c77ff;">Here</a>.',
    },
    {
      attachTo: { element: '.create-sidebar', on: 'right' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'Here you can create a new service starting from a predefined template.',
    },
    {
      attachTo: { element: '.pagerduty-container', on: 'bottom' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'This is where you can find information about the Out Of Hours SRE on-call engineers and how to contact with them.',
    },
    {
      attachTo: { element: '.useful-links-section', on: 'bottom' },
      buttons: middleButtons,
      ...commonConfig,
      title,
      text: 'Here are some Catalog quick links.',
    },
    {
      attachTo: { element: '.useful-links-section', on: 'bottom' },
      buttons: lastButtons,
      ...commonConfig,
      title,
      text: 'And finally here you can find some useful links.',
    },
  ]);

  return (
    <Button variant="contained" color="primary" onClick={tour.start}>
      Start Tour
    </Button>
  );
};

export default HomeTour;
